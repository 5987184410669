import {
    borderRadiusLarge,
    borderRadiusMedium,
    borderRadiusSmall,
} from "@porsche-design-system/components-react/styles";

const radii = {
    large: borderRadiusLarge,
    medium: borderRadiusMedium,
    small: borderRadiusSmall,
    full: "9999px",
};

export default radii;
