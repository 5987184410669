import {
    breakpointBase,
    breakpointXS,
    breakpointS,
    breakpointM,
    breakpointL,
    breakpointXL,
    breakpointXXL,
} from "@porsche-design-system/components-react/styles";
import space from "../03.space/space";

const sizes = {
    ...space,
    wrapperContainer: "1920px",
    halfWrapperContainer: "960px",
    navQuickLinksHeight: "44px",
    liveTickerHeight: "40px",
    screenHeightWithLiveTicker: "calc(100svh - 40px)",
    breakpoints: {
        base: `${breakpointBase}px`,
        xs: `${breakpointXS}px`,
        s: `${breakpointS}px`,
        m: `${breakpointM}px`,
        l: `${breakpointL}px`,
        xl: `${breakpointXL}px`,
        xxl: `${breakpointXXL}px`,
    },
    icon: {},
};

export default sizes;
