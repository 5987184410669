import type { IDataLayer, ISnippets } from "./type/GoogleTagManagerType";

export const DEFAULT_DOMAIN = "https://www.googletagmanager.com";
export const DEFAULT_SCRIPT_NAME = "gtm.js";

export const getDataLayerSnippet = (
    dataLayer: Pick<IDataLayer, "dataLayer">["dataLayer"],
    dataLayerName: Pick<IDataLayer, "dataLayerName">["dataLayerName"] = "dataLayer"
): Pick<ISnippets, "gtmDataLayer">["gtmDataLayer"] =>
    `window.${dataLayerName} = window.${dataLayerName} || [];` +
    (dataLayer ? `window.${dataLayerName}.push(${JSON.stringify(dataLayer)})` : "");
