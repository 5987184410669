import type { ReactNode, RefObject } from "react";
import { useCallback, useMemo, createContext, useContext, useEffect, useState } from "react";
import type { ProcessorValue } from "@/components/user-centrics/known-processor-names";
import { useRouter } from "next/router";
import { useSiteSettingsQuery } from "@/lib/contentful/site-settings/__generated/site-settings.contentful.generated";
import { getUcPrivacyShield, getUsercentrics } from "@/components/user-centrics/utils";
import Script from "next/script";

const escapeValue = (value: string) => JSON.stringify(value);

/** in the case of missing content from Contentful, the following fallback object is used - we MUST be able to show this privacy shield for legal reasons */
const privacyShieldStaticFallback = {
    bgimage:
        "https://images.ctfassets.net/r1mi7scjbdxh/3ZrpWjGiBvpJufCM0Y85qC/268a8c91cf1343f1e5e4e1efc23a9b76/uc-privacy-shield-background.png",
    consentunavailable:
        "The content required to display this page could not be loaded. If you are using an AdBlocker, please check its settings.",
    consentservice: {
        headline: "Note on data processing",
        text: "We provide content from ###SERVICE### on our website. To view this content, you must agree to data processing by ###SERVICE###.",
        buttons: {
            consent: {
                label: "Agree",
            },
            privacy: {
                label: "Notes on data protection",
                link: "https://www.porsche.com/international/privacy/",
            },
        },
    },
};

type ConsentGivenReference = {
    dataProcessor: string;
    consentStatus: boolean;
    elements: Array<HTMLElement>;
};

type State = {
    isLoaded: boolean;

    consentGiven: Map<ProcessorValue, ConsentGivenReference>;

    checkConsent: (params: {
        mapContainer: Array<RefObject<HTMLElement>>;
        processor: ProcessorValue;
        successCallback: () => void;
    }) => void;

    toggleConsentDialogLayer2: (showDialog: boolean) => void;
    toggleConsentDialogLayer4: (showDialog: boolean) => void;
};

const UsercentricsContext = createContext<State | undefined>(undefined);

type UsercentricsProviderProps = {
    children: ReactNode;
    siteSettingsId: string;
};

const UsercentricsProvider = ({ children, siteSettingsId }: UsercentricsProviderProps) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [consentGiven, setConsentGiven] = useState<State["consentGiven"]>(new Map());

    const { locale, isPreview } = useRouter();

    const firstPartOfLocale = locale?.split("-")[0];

    const { error, data } = useSiteSettingsQuery(
        {
            preview: isPreview,
            locale: locale!,
            id: siteSettingsId,
        },
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        const handleUcReady = () => {
            setIsLoaded(true);
        };

        window.addEventListener("ucReady", handleUcReady, { once: true });

        const handleUsercentricsEvents = (e: CustomEvent) => {
            const { event, name, status } = e.detail.data;

            if (event === "consent_changed") {
                setConsentGiven((prevState) => {
                    const newState = new Map(prevState);

                    const elements = prevState.get(name)?.elements ?? [];

                    newState.set(name, {
                        dataProcessor: name,
                        consentStatus: status,
                        elements,
                    });

                    return newState;
                });
            }
        };

        window.addEventListener("usercentrics-events", handleUsercentricsEvents as EventListener);

        return () => {
            window.removeEventListener("ucReady", handleUcReady);
            window.removeEventListener(
                "usercentrics-events",
                handleUsercentricsEvents as EventListener
            );
        };
    }, []);

    const checkConsent = useCallback(
        ({ mapContainer, processor, successCallback }: Parameters<State["checkConsent"]>[0]) => {
            if (!isLoaded) {
                if (process.env.NODE_ENV === "development") {
                    window.console.error(
                        "`ucPrivacyShield` is not available on the window object yet"
                    );
                }
                return;
            }

            if (!mapContainer && process.env.NODE_ENV === "development") {
                window.console.error("mapContainer is required");
            }

            const ucPrivacyShield = getUcPrivacyShield();

            const _mapContainer = mapContainer
                .map((ref) => ref.current)
                .filter(Boolean) as Array<HTMLElement>;

            if (!ucPrivacyShield?.checkConsent) {
                if (process.env.NODE_ENV === "development") {
                    window.console.error(
                        "`ucPrivacyShield.checkConsent` is not available on the window object"
                    );
                }
                return;
            }

            ucPrivacyShield.checkConsent({
                mapContainer: _mapContainer,
                processorId: processor,
                timeoutVar: {},
                callbacks: {
                    success: () => {
                        setConsentGiven((prevState) => {
                            const newState = new Map(prevState);

                            newState.set(processor, {
                                dataProcessor: processor,
                                consentStatus: true,
                                elements: [
                                    ...(prevState.get(processor)?.elements ?? []),
                                    ..._mapContainer,
                                ],
                            });

                            return newState;
                        });
                        successCallback();
                    },
                    p: undefined,
                },
            });
        },
        [isLoaded]
    );

    const toggleConsentDialogLayer2 = useCallback(
        (showDialog: boolean) => {
            const usercentrics = getUsercentrics();

            if (!isLoaded || !usercentrics) {
                if (process.env.NODE_ENV === "development") {
                    window.console.error(
                        "`usercentrics` is not available on the window object, or the SDK is not loaded"
                    );
                }
                return;
            }

            usercentrics.toggleCenteredModalIsVisible(showDialog);
        },
        [isLoaded]
    );

    const toggleConsentDialogLayer4 = useCallback(
        (showDialog: boolean) => {
            const usercentrics = getUsercentrics();

            if (!isLoaded || !usercentrics) {
                if (process.env.NODE_ENV === "development") {
                    window.console.error(
                        "`usercentrics` is not available on the window object, or the SDK is not loaded"
                    );
                }
                return;
            }

            usercentrics.toggleConsentInfoModalIsVisible(showDialog);
        },
        [isLoaded]
    );

    const value = useMemo<State>(
        () => ({
            isLoaded,
            consentGiven,
            checkConsent,
            toggleConsentDialogLayer2,
            toggleConsentDialogLayer4,
        }),
        [isLoaded, consentGiven, checkConsent, toggleConsentDialogLayer2, toggleConsentDialogLayer4]
    );

    if (error || !data?.siteSettings) {
        return null;
    }

    const configScript = `
    var GlobalConsent = GlobalConsent || {};
  	GlobalConsent.Language = ["${firstPartOfLocale}"];

    GlobalConsent.widgetFallback = ${escapeValue(data.siteSettings.privacyShieldConsentUnavailableText ?? privacyShieldStaticFallback.consentunavailable)};

    window.ucPrivacyShield = {
        bgimage: ${escapeValue(data.siteSettings.privacyShieldBackgroundImage?.url ?? privacyShieldStaticFallback.bgimage)},
        consentunavailable: {
            text: ${escapeValue(data.siteSettings.privacyShieldConsentUnavailableText ?? privacyShieldStaticFallback.consentunavailable)}
        },
        consentservice: {
            headline: ${escapeValue(data.siteSettings.privacyShieldHeadline ?? privacyShieldStaticFallback.consentservice.headline)},
            text: ${escapeValue(data.siteSettings.privacyShieldBodyText ?? privacyShieldStaticFallback.consentservice.text)},
            buttons: {
                consent: {
                    label: ${escapeValue(data.siteSettings.privacyShieldConsentButtonLabel ?? privacyShieldStaticFallback.consentservice.buttons.consent.label)}
                },
                privacy: {
                    label: ${escapeValue(data.siteSettings.privacyShieldPrivacyInfoButtonLabel ?? privacyShieldStaticFallback.consentservice.buttons.privacy.label)},
                    link: ${escapeValue(data.siteSettings.privacyShieldPrivacyInfoButtonUrl ?? privacyShieldStaticFallback.consentservice.buttons.privacy.link)}
                }
            }
        }
    };
  `;

    return (
        <UsercentricsContext.Provider value={value}>
            <script dangerouslySetInnerHTML={{ __html: configScript }} />
            {data.siteSettings.cookieSettingsId && (
                <Script
                    id={data.siteSettings.cookieSettingsId}
                    src="https://www.porsche.com/all/usercentrics/udg-uc-sdk.min.js"
                    type="text/javascript"
                    async
                    defer
                />
            )}
            {children}
        </UsercentricsContext.Provider>
    );
};

const useUsercentrics = () => {
    const context = useContext(UsercentricsContext);
    if (context === undefined) {
        throw new Error("useUsercentrics must be used within a UsercentricsProvider");
    }
    return context;
};

export { UsercentricsProvider, useUsercentrics };
