import { getDataLayerSnippet } from "./snippets";
import type { ISendToGTM, ISetupGTM, ISnippetsParams } from "./type/GoogleTagManagerType";

const setupGTM = (params: ISnippetsParams): ISetupGTM => {
    const getDataLayerScript = (): HTMLElement => {
        const dataLayerScript = document.createElement("script");
        dataLayerScript.innerHTML = getDataLayerSnippet(params.dataLayer, params.dataLayerName);
        return dataLayerScript;
    };

    return {
        getDataLayerScript,
    };
};

interface DataLayerEntry {
    [key: string]: unknown;
}

interface Window {
    [key: string]: DataLayerEntry[];
}

export const initGTM = ({ dataLayer, dataLayerName }: ISnippetsParams): void => {
    const gtm = setupGTM({
        dataLayer,
        dataLayerName,
    });

    const dataLayerScript = gtm.getDataLayerScript();

    document.head.insertBefore(dataLayerScript, document.head.childNodes[0]);
};

export const sendToGTM = ({ dataLayerName, data }: ISendToGTM): void => {
    const windowTyped = window as unknown as Window;

    if (Array.isArray(windowTyped[dataLayerName])) {
        windowTyped[dataLayerName].push(data as DataLayerEntry);
    }
};
