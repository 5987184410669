import { theme as chakraDefaultTheme } from "@chakra-ui/react";

const space = {
    ...chakraDefaultTheme.space,
    navHeightMobile: "130px",
    navHeightDesktop: "72px",
    liveTickerHeight: "40px",
};

export default space;
