const aspectRatios = {
    "1:1": "1 / 1",
    "6:7": "6 / 7",
    "7:6": "7 / 6",
    "4:3": "4 / 3",
    "4:5": "4 / 5",
    "3:4": "3 / 4",
    "7:5": "7 / 5",
    "5:7": "5 / 7",
    "3:2": "3 / 2",
    "2:3": "2 / 3",
    "16:10": "16 / 10",
    "10:16": "10 / 16",
    "5:3": "5 / 3",
    "5:4": "5 / 4",
    "3:5": "3 / 5",
    "16:9": "16 / 9",
    "9:16": "9 / 16",
    "2:1": "2 / 1",
    "1:2": "1 / 2",
    "21:9": "21 / 9",
    "9:21": "9 / 21",
    golden: "1.618 / 1",
    goldenVertical: "1 / 1.618",
};

export default aspectRatios;
