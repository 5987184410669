import { useEffect } from "react";
import { useRouter } from "next/router";
import trackGTMEvent from "@/components/google-tag-manager-tracking/gtmUtils";

const GTMInitializer: React.FC = () => {
    const { locale, asPath } = useRouter();

    useEffect(() => {
        trackGTMEvent({
            customEventAction: "PAGMSH_General_Pageload",
            locale: locale,
            asPath: asPath,
        });
    }, [asPath, locale]);

    return null;
};

export default GTMInitializer;
