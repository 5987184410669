import {
    dropShadowHighStyle,
    dropShadowLowStyle,
    dropShadowMediumStyle,
} from "@porsche-design-system/components-react/styles";

const shadows = {
    low: dropShadowLowStyle.boxShadow,
    medium: dropShadowMediumStyle.boxShadow,
    high: dropShadowHighStyle.boxShadow,
};

export default shadows;
