import { createContext, useContext } from "react";
import type { FetchMicrocopySetsResults } from "@/lib/contentful/microcopy/fetch-microcopy-sets";
import { get, getAsPluralised } from "@/lib/contentful/microcopy/microcopy";
import { useRouter } from "next/router";
import { type MicrocopySetFieldsFragment } from "../__generated/graphql.types";

export type MicrocopyInputType =
    | null
    | ({
          __typename?: "MicrocopySet";
      } & MicrocopySetFieldsFragment);

export type MicrocopyContextState = FetchMicrocopySetsResults;

export interface MicrocopyContextAPI {
    add: (microcopySet: MicrocopyInputType) => void;
    get: (microcopySetKey: string, microcopyKey: string) => string;
    getAsPluralised: (
        microcopySetKey: string,
        microcopyKey: string,
        count: number,
        locale?: string,
        options?: Intl.PluralRulesOptions
    ) => string;
}

const MicrocopyContext = createContext<MicrocopyContextState | undefined>(undefined);

const { Provider: MicrocopyProvider } = MicrocopyContext;

const useMicrocopy = (): MicrocopyContextAPI => {
    const context = useContext(MicrocopyContext);
    const { locale: routerLocale } = useRouter();

    if (context === undefined) {
        throw new Error("useMicrocopy must be used within MicrocopyProvider");
    }

    return {
        add: (microcopySet: MicrocopyInputType) => {
            if (!microcopySet?.key) return;
            context[microcopySet.key] = microcopySet.microcopyCollection?.items;
        },
        get: (microcopySetKey, microcopyKey) => get(context, microcopySetKey, microcopyKey),
        getAsPluralised: (microcopySetKey, microcopyKey, count, locale, options) =>
            getAsPluralised(
                context,
                microcopySetKey,
                microcopyKey,
                locale ?? routerLocale!,
                count,
                options
            ),
    };
};

export { MicrocopyContext, MicrocopyProvider, useMicrocopy };
