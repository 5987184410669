import { initGTM, sendToGTM } from "./GoogleTagManager";
import type { PagData, TrackGTMEventProps } from "./type/GoogleTagManagerType";
import { getLocale, getCountry, getEnvironment, getPageId } from "@/common/helpers/tracking";

/**
 * trackGTMEvent - A custom tracking util for tracking user interactions and page loads with Google Tag Manager (GTM).
 *
 * @param {string} customEventAction - Required. The custom event action name. Default is "PAGMSH_General_Pageload".
 * @param {string} locale - Required. The locale of the current page.
 * @param {string} asPath - Required. The asPath of the current page.
 * @param {boolean} componentClick - Optional. componentClick for click events custom data to be included in the tracking payload.
 * @param {boolean} componentDisplay - Optional. componentDisplay for displaying data to be included in the tracking payload.
 * @param {string} targetUrl - Required.
 * @param {string} targetType - Required.
 * @param {string} clickElementId - Required.
 * @param {string} clickElementName - Required.
 * @param {string} clickElementType - Required.
 * @param {string} displayElementId - Required.
 * @param {string} displayElementName - Required.
 * @param {string} displayElementType - Required.
 *
 * Usage:
 *
 * 1. Import the tracking util in your component:
 * import trackGTMEvent from "@/components/google-tag-manager-tracking/gtmUtils";
 * *
 * 2. Call the trackGTMEvent with the necessary parameters within your component:
 * Example:
 *       trackGTMEvent({
 *           customEventAction: "Your_curstom_event_action_name",
 *           componentClick: true,
 *           targetUrl: targetUrl,
 *           targetType: targetType,
 *           locale: locale,
 *           asPath: asPath,
 *       });
 */

const trackGTMEvent = ({
    componentClick = false,
    clickElementName,
    clickElementId,
    customEventAction = "PAGMSH_General_Pageload",
    componentDisplay = false,
    displayElementName,
    displayElementId,
    targetUrl,
    targetType,
    locale,
    asPath,
}: TrackGTMEventProps) => {
    const localeTracking = getLocale(locale);
    const pageName = getPageId(asPath);
    const country = getCountry(locale);
    const shortenEnv = getEnvironment();

    const pagData: PagData = {
        context: {
            eventAction: customEventAction,
            applicationId: "motorsport-Hub-2",
            language: localeTracking,
            country: country,
            currency: "EUR",
            environment: shortenEnv,
            timestamp: new Date().toISOString(),
            server: process.env.NEXT_PUBLIC_HOSTNAME || "",
        },
        visitor: {
            loginStatus: "false",
            deviceBrowserHeight: window.innerHeight.toString(),
            deviceBrowserWidth: window.innerWidth.toString(),
            deviceBrowserBreakpoint: window.innerHeight.toString(),
            deviceBrowserOrientation: window.matchMedia("(orientation: portrait)").matches
                ? "p"
                : "l",
            deviceType: window.matchMedia("(pointer: coarse)").matches ? "mobile" : "desktop",
        },
        pageExperience: {
            pageId: pageName,
            pageName: asPath,
        },
        ...(componentClick && {
            componentClick: {
                clickElementType: targetType,
                clickElementId: clickElementId,
                clickElementName: clickElementName,
                targetUrl: targetUrl,
            },
        }),
        ...(componentDisplay && {
            componentDisplay: {
                displayElementType: targetType,
                displayElementId: displayElementId,
                displayElementName: displayElementName,
            },
        }),
    };

    initGTM({
        dataLayer: pagData,
        dataLayerName: "pagData",
    });

    sendToGTM({
        dataLayerName: customEventAction,
        data: pagData,
    });
};

export default trackGTMEvent;
