const rem210938 = "2.10938rem";
const rem2 = "2rem";
const rem175 = "1.75rem";
const rem15 = "1.5rem";
const rem1125 = "1.125rem";

const lineHeights = {
    desktopDisplayLarge: "6.325rem",
    desktopDisplayMedium: "4.6rem",
    desktopDisplaySmall: "3.7375rem",

    desktopHeadingXXLarge: "3.25rem",
    desktopHeadingXLarge: "2.6rem",
    desktopHeadingLarge: rem210938,
    desktopHeadingMedium: rem175,
    desktopHeadingSmall: rem15,

    desktopTextXLarge: "2.6rem",
    desktopTextLarge: rem210938,
    desktopTextMedium: rem175,
    desktopTextSmall: rem15,
    desktopTextXSmall: "1.3125rem",
    desktopTextXXSmall: rem1125,

    mobileDisplayLarge: "2.875rem",
    mobileDisplayMedium: "2.86875rem",
    mobileDisplaySmall: "2.53125rem",

    mobileHeadingXXLarge: "2.19375rem",
    mobileHeadingXLarge: rem2,
    mobileHeadingLarge: rem210938,
    mobileHeadingMedium: "1.6875rem",
    mobileHeadingSmall: rem15,

    mobileTextXLarge: rem2,
    mobileTextLarge: rem175,
    mobileTextMedium: "1.625rem",
    mobileTextSmall: rem15,
    mobileTextXSmall: rem1125,
    mobileTextXXSmall: rem1125,

    desktopCaption: ".93438rem",
    mobileCaption: ".93438rem",
};

export default lineHeights;
