enum Environment {
    Production = "P",
    Development = "D",
}

export const getEnvironment = () => {
    return process.env.NODE_ENV === "production" ? Environment.Production : Environment.Development;
};

export const getCountry = (locale: string | undefined) => {
    const localeTracking = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

    switch (localeTracking) {
        case "en":
            return "international";
        case "de-DE":
            return "DE";
        case "en-US":
            return "US";
        default:
            return "international";
    }
};

export const getLocale = (locale: string | undefined) => {
    return locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
};

export const getPageId = (asPath: string | undefined) => {
    return `https://` + process.env.NEXT_PUBLIC_HOSTNAME + asPath;
};

export const getTargetUrl = (targetUrl: string | undefined) => {
    return `https://` + process.env.NEXT_PUBLIC_HOSTNAME + targetUrl;
};
