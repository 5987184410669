import { PLink } from "@porsche-design-system/components-react/ssr";
import type { PLinkProps } from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkProps = Omit<NextLinkProps, "as"> & Omit<PLinkProps, "href">;

const ChakraPLink = chakra<typeof PLink, LinkProps>(PLink);

const Link = forwardRef<LinkProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLink {...props} ref={ref} />;
    }

    return (
        <NextLink href={props.href} passHref legacyBehavior>
            <ChakraPLink {...props} ref={ref} />
        </NextLink>
    );
});

export { Link };
export type { LinkProps };
