const fontSizes = {
    desktopDisplayLarge: "5.5rem",
    desktopDisplayMedium: "4rem",
    desktopDisplaySmall: "3.25rem",

    desktopHeadingXXLarge: "2.5rem",
    desktopHeadingXLarge: "2rem",
    desktopHeadingLarge: "1.5625rem",
    desktopHeadingMedium: "1.25rem",
    desktopHeadingSmall: "1rem",

    desktopTextXLarge: "2rem",
    desktopTextLarge: "1.5625rem",
    desktopTextMedium: "1.25rem",
    desktopTextSmall: "1rem",
    desktopTextXSmall: ".875rem",
    desktopTextXXSmall: ".75rem",

    mobileDisplayLarge: "2.5rem",
    mobileDisplayMedium: "2.125rem",
    mobileDisplaySmall: "1.875rem",

    mobileHeadingXXLarge: "1.625rem",
    mobileHeadingXLarge: "1.4375rem",
    mobileHeadingLarge: "1.25rem",
    mobileHeadingMedium: "1.125rem",
    mobileHeadingSmall: "1rem",

    mobileTextXLarge: "1.4375rem",
    mobileTextLarge: "1.25rem",
    mobileTextMedium: "1.125rem",
    mobileTextSmall: "1rem",
    mobileTextXSmall: ".8125rem",
    mobileTextXXSmall: ".75rem",

    desktopCaption: ".8125rem",
    mobileCaption: ".8125rem",
};

export default fontSizes;
