import type { ContentfulCloudinaryAssetField } from "@project/ui";
import { getCldOgImageUrl } from "@project/ui";

export const OPEN_GRAPH_IMAGE_WIDTH = 1200;
export const OPEN_GRAPH_IMAGE_HEIGHT = 627;

export const resolveOpenGraphImage = (openGraphAsset: ContentfulCloudinaryAssetField) => {
    if (!openGraphAsset?.[0]?.public_id) {
        return null;
    }

    return getCldOgImageUrl({
        src: openGraphAsset[0].public_id,
        assetType: openGraphAsset[0].resource_type ?? "image",
        format: "jpg",
        ...(openGraphAsset[0].resource_type === "video" && { rawTransformations: "so_0.0" }),
    });
};

export const getLanguageAlternates = (
    locales: Array<string>,
    defaultLocale: string,
    path: string
) => {
    return locales.map((locale) => {
        const url = [
            `https://${process.env.NEXT_PUBLIC_HOSTNAME}`,
            locale === defaultLocale ? path : `/${locale}${path}`,
        ].join("");

        return {
            hrefLang: locale === defaultLocale ? "x-default" : locale,
            href: url,
        };
    });
};
