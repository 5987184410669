export const fetchConfig = {
    endpoint: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
    params: {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_CDA_API_ACCESS_TOKEN}`,
        },
    },
    previewParams: {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_CPA_API_ACCESS_TOKEN}`,
        },
    },
};

export const customFetcher = <TData, TVariables extends { preview?: boolean | null }>(
    query: string,
    variables?: TVariables,
    options?: RequestInit["headers"]
) => {
    return async (): Promise<TData> => {
        const res = await fetch(fetchConfig.endpoint, {
            method: "POST",
            ...options,
            ...(variables?.preview ? fetchConfig.previewParams : fetchConfig.params),
            body: JSON.stringify({ query, variables }),
        });

        const json = await res.json();

        if (json.errors && !json.data) {
            const { message } = json.errors[0];

            throw new Error(message);
        }

        return json.data as TData;
    };
};
