import type { Styles } from "@chakra-ui/theme-tools";
import { getFocusStyle } from "@porsche-design-system/components-react/styles";

export const globalStyles: Styles = {
    global: {
        body: {
            bgColor: "white",
        },
        ".rte :where(blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre)": {
            mb: "1rem",
        },
        ".rte :where(p)": {
            whiteSpace: "pre-line",
        },
        ".focus-visible": {
            ...getFocusStyle(),
        },
    },
};

export const gridGapMobile = 4;
export const gridGapDesktop = 8;
export const gridTemplateColumnsBase = "repeat(2, 1fr)";
export const gridTemplateColumnsLarge = "repeat(12, 1fr)";
export const gridTemplateColumns = {
    base: gridTemplateColumnsBase,
    l: gridTemplateColumnsLarge,
};
export const gridGap = {
    base: gridGapMobile,
    l: gridGapDesktop,
};
