import { PButton } from "@porsche-design-system/components-react/ssr";
import type { PButtonProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ButtonProps = PButtonProps & BoxProps;

const Button = chakra<typeof PButton, PButtonProps>(PButton);

export { Button };
export type { ButtonProps };
